<template>
  <Dialog>
    <LoginForm
      :email="dialogStore.getDialogData(DialogIdent.LOGIN)?.email || ''"
      :show-register-link="canRegister"
      @update-view="handleUpdate"
      @click-register="
        () => canRegister && dialogStore.openDialog(DialogIdent.REGISTRATION)
      "
    >
      <template #hint>
        <div class="px-sm md:px-0 mx-auto mt-[20px] w-fit">
          <InfoFrame
            v-show="dialogStore.getDialogData(DialogIdent.LOGIN)?.showHint"
          >
            {{ t(dialogStore.getDialogData(DialogIdent.LOGIN)?.text) }}
          </InfoFrame>
        </div>
      </template>
    </LoginForm>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import LoginForm from '@/components/form/login/loginForm/minilu/loginForm.vue';
import InfoFrame from '~/components/formFields/components/infoFrame.vue';
import { Views } from '~/components/form/login/useLoginForm';
import {
  DialogIdent,
  useDialogStore,
  DialogResponses,
} from '~/stores/useDialogStore';

const { t } = useTrans();
const dialogStore = useDialogStore();
dialogStore.setHeadline('login.headline');

const data = dialogStore.getDialogData(DialogIdent.LOGIN);
const canRegister = data?.canRegister ?? true;

function handleUpdate(v: Views, data?: { legacyHash: string; email?: string }) {
  if (v === Views.LEGACY) {
    dialogStore.openDialog(DialogIdent.RESET_LEGACY_PASSWORD, data);
  } else if (v === Views.FORGOT) {
    dialogStore.openDialog(DialogIdent.SEND_FORGOT_PASSWORD_EMAIL);
  } else {
    dialogStore.closeDialog(DialogResponses.SUCCESS);
  }
}
</script>
